<template>
  <base-layout-two page-title="Reporting" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Reports</h1>
    </section>

    <ion-list class="padding-x-16">
      <report-list-item
        v-for="report in reports"
        :key="report.id"
        :router-link="`/reporting/${report.url}`"
        :report="report"
      >
      </report-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList } from "@ionic/vue";
import ReportListItem from "@/components/reporting/ReportListItem.vue";

export default {
  components: {
    IonList,
    ReportListItem,
  },

  data() {
    return {
      reports: [
        {
          id: 0,
          name: "Reservations Status",
          url: "reservations_status",
        }
      ],
    };
  },

  methods: {},
};
</script>